<script lang="ts">
	import { formatAmount, type AmountLike } from '$lib/utils/currency'


	interface Props {
		loading?: boolean;
		rounded?: boolean;
		amount: AmountLike;
		originalAmount?: AmountLike | undefined;
		options?: Intl.NumberFormatOptions;
		class?: string;
	}

	let {
		loading = false,
		rounded = false,
		amount,
		originalAmount = undefined,
		options = undefined,
		class: className = ''
	}: Props = $props();
	

	let formattedAmount = $derived(loading
		? '123.00'
		: formatAmount(amount?.amount, amount?.currency, rounded, options))

	let formattedOriginalAmount = $derived(originalAmount
		? formatAmount(originalAmount?.amount, originalAmount?.currency, rounded, options)
		: null)

	let hasDiscount = $derived(originalAmount && originalAmount.amount > amount.amount)
</script>

<span data-testid="amount" class={`${className} ${loading ? 'blur-sm' : ''}`}>
	{#if hasDiscount}
		<span class="line-through text-brand-gray-4 mr-2">{formattedOriginalAmount}</span>
	{/if}
	<span class={hasDiscount ? 'text-black font-semibold' : ''}>{formattedAmount}</span>
</span>
