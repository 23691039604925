import { browser } from '$app/environment'

export interface AmountLike {
	amount: string | number
	currency: string
}

export function toAmount(amountInCents: number | null, currency = 'USD'): AmountLike {
	const safeAmount = amountInCents ?? 0

	if (isZeroDecimalCurrency(currency)) {
		return {
			amount: safeAmount,
			currency,
		}
	}

	return {
		amount: safeAmount / 100,
		currency,
	}
}

const getNavigatorLanguage = () => {
	if (!browser) return 'en-US'
	return navigator.languages && navigator.languages.length
		? navigator.languages[0]
		: // @ts-ignore
			navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en-US'
}

const currencyLocales: Record<string, string> = {
	USD: 'en-US',
	EUR: 'de-DE',
	GBP: 'en-GB',
	JPY: 'ja-JP',
}

// export function formatAmount(
// 	amount: number | string | null,
// 	currency = 'USD',
// 	rounded = false,
// 	options?: Intl.NumberFormatOptions,
// ) {
// 	const safeCurrency = currency || 'USD'
// 	let safeAmount = Number(amount ?? 0)
// 	const locale = currencyLocales[safeCurrency] || 'en-US'

// 	// Handle rounding before formatting
// 	if (rounded) {
// 		safeAmount = Math.ceil(safeAmount)
// 	}

// 	const formatOptions: Intl.NumberFormatOptions = {
// 		style: 'currency',
// 		currency: safeCurrency,
// 		currencyDisplay: 'symbol',
// 		...options,
// 	}

// 	if (rounded) {
// 		formatOptions.minimumFractionDigits = 0
// 		formatOptions.maximumFractionDigits = 0
// 	} else if (isZeroDecimalCurrency(safeCurrency)) {
// 		formatOptions.minimumFractionDigits = 0
// 		formatOptions.maximumFractionDigits = 0
// 		// For non-rounded zero decimal currencies, round to nearest integer
// 		if (!rounded) {
// 			safeAmount = Math.round(safeAmount)
// 		}
// 	} else {
// 		formatOptions.minimumFractionDigits = 2
// 		formatOptions.maximumFractionDigits = 2
// 	}

// 	const formatter = new Intl.NumberFormat(locale, formatOptions)
// 	return formatter.format(safeAmount)
// }

export function formatAmount(
	amount: number | string,
	currency = 'USD',
	roundDown = false,
	options?: Intl.NumberFormatOptions,
) {
	if (currency == '' || currency == null) currency = 'USD'

	const value = Number(amount ?? 0)
	const isZeroDecimal = isZeroDecimalCurrency(currency)

	// Special case for compact notation
	if (options?.notation === 'compact') {
		const magnitude = Math.abs(value)
		if (magnitude >= 1e6 && magnitude < 1e9) {
			const millions = value / 1e6
			return `$${millions}M`
		}
		if (magnitude >= 1e9 && magnitude < 1e12) {
			const billions = value / 1e9
			return `$${billions}B`
		}
		if (magnitude >= 1e12) {
			const trillions = value / 1e12
			return `$${trillions}T`
		}
	}

	// For zero decimal currencies with rounded=true, use floor instead of ceil
	const finalValue = roundDown
		? isZeroDecimal
			? Math.floor(value)
			: Math.ceil(value)
		: isZeroDecimal
			? Math.round(value)
			: value

	return new Intl.NumberFormat(getNavigatorLanguage(), {
		style: 'currency',
		currency,
		minimumFractionDigits: roundDown || isZeroDecimal ? 0 : 2,
		maximumFractionDigits: roundDown || isZeroDecimal ? 0 : 2,
		...options,
	}).format(finalValue)
}

export function isZeroDecimalCurrency(currency: string) {
	switch (currency) {
		case 'BIF': // Burundi Franc
		case 'CLP': // Chilean Peso
		case 'DJF': // Djiboutian Franc
		case 'GNF': // Guinean Franc
		case 'JPY': // Japanese Yen
		case 'KMF': // Comorian Franc
		case 'KRW': // South Korean Won
		case 'MGA': // Malagasy Ariary
		case 'PYG': // Paraguayan Guarani
		case 'RWF': // Rwandan Franc
		case 'UGX': // Ugandan Shilling
		case 'VND': // Vietnamese Dong
		case 'VUV': // Vanuatu Vatu
		case 'XAF': // Central African CFA Franc
		case 'XOF': // West African CFA Franc
		case 'XPF': // CFP Franc
			return true
		default:
			return false
	}
}

export const compactCurrencies = [
	'JPY',
	'IDR',
	'ARS',
	'THB',
	'PHP',
	'TRY',
	'ZAR',
	'COP',
	'EGP',
	'INR',
	'KRW',
	'RUB',
	'TWD',
	'VND',
	'BRL',
	'LBP',
]

export const supportedCurrencies = [
	{ id: 'AED', name: 'United Arab Emirates Dirham' },
	{ id: 'ARS', name: 'Argentine Peso' },
	{ id: 'AUD', name: 'Australian Dollar' },
	{ id: 'BRL', name: 'Brazilian Real' },
	{ id: 'CAD', name: 'Canadian Dollar' },
	{ id: 'CHF', name: 'Swiss Franc' },
	{ id: 'CNY', name: 'Chinese Yuan' },
	{ id: 'COP', name: 'Columbian Peso' },
	{ id: 'DKK', name: 'Danish Krone' },
	{ id: 'EGP', name: 'Egyptian Pound' },
	{ id: 'EUR', name: 'Euro' },
	{ id: 'GBP', name: 'British Pound' },
	{ id: 'HKD', name: 'Hong Kong Dollar' },
	{ id: 'IDR', name: 'Indonesian Rupiah' },
	{ id: 'ILS', name: 'Israeli New Shekel' },
	{ id: 'INR', name: 'Indian Rupee' },
	{ id: 'JPY', name: 'Japanese Yen' },
	{ id: 'KRW', name: 'South Korean Won' },
	{ id: 'LBP', name: 'Lebanese Pound' },
	{ id: 'MAD', name: 'Moroccan Dirham' },
	{ id: 'MXN', name: 'Mexican Peso' },
	{ id: 'MYR', name: 'Malaysian Ringgit' },
	{ id: 'NOK', name: 'Norwegian Krone' },
	{ id: 'NZD', name: 'New Zealand Dollar' },
	{ id: 'PHP', name: 'Philippine Peso' },
	{ id: 'PLN', name: 'Polish Zloty' },
	{ id: 'QAR', name: 'Qatari Rial' },
	{ id: 'RUB', name: 'Russian Ruble' },
	{ id: 'SAR', name: 'Saudi Riyal' },
	{ id: 'SEK', name: 'Swedish Krona' },
	{ id: 'SGD', name: 'Singapore Dollar' },
	{ id: 'THB', name: 'Thai Baht' },
	{ id: 'TRY', name: 'Turkish Lira' },
	{ id: 'TWD', name: 'New Taiwan Dollar' },
	{ id: 'USD', name: 'United States Dollar' },
	{ id: 'VND', name: 'Vietnamese Dong' },
	{ id: 'ZAR', name: 'South African Rand' },
]
